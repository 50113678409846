import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: { // 预留根路由
      name: 'home'
    }
  },
  { //主页
    path: '/home',
    name: 'home',
    component: resolve => require(['@/views/home-page'], resolve)
  },
  { //专辑页
    path: '/albumDetail',
    name: 'albumDetail',
    component: resolve => require(['@/views/albumDetail'], resolve)
  },
  { //视频页
    path: '/videoDetail',
    name: 'videoDetail',
    component: resolve => require(['@/views/videoDetail'], resolve)
  },
  { //推荐页
    path: '/apps',
    name: 'apps',
    component: resolve => require(['@/views/apps'], resolve)
  },
  { //隐私政策
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: resolve => require(['@/views/privacyPolicy'], resolve)
  }
]

const router = new VueRouter({
  routes
})

export default router
