import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/common/css/global.css'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

// 1.图片懒加载插件
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
// 2.注册插件
Vue.use(VueLazyload, {
  preLoad: 1.7,
  // 懒加载默认加载图片
  loading: require('@/common/images/img_loading@3x.png'),
  // 加载失败后加载的图片
  error: require('@/common/images/img_loading@3x.png'),
  attempt: 1
  // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
  // listenEvents: [ 'scroll' ]
})

Vue.use(VueGtag, {
  config: { 
    id: "G-TMWJ2L5LHF",
    params: {
      send_page_view: false
    }
  }
}, router)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
